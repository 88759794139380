<template>
  <div>
    <v-card
      height="40px"
      width="100%"
      flat
      class="d-flex align-center"
      style="background: rgba(127, 145, 155, 0.052607)"
    >
      <v-spacer></v-spacer>
      <v-chip
        x-small
        class="mr-4 py-2 mt-0 mt-md-1 mr-sm-6 mr-md-8 float-right chip"
        style="color: #596a73; background: #d8d8d8"
      >
        {{ currency }}
      </v-chip>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "buttonActionContainer",
  props: {
    currency: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      //
    };
  },
};
</script>

<style lang="scss" scoped></style>

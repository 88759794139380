<template>
  <div>
    <v-dialog v-model="dialog" max-width="574">
      <v-card color="#f7f7f7" class="rounded-lg">
        <v-form ref="editVendorForm">
          <v-card-title
            style="background: #ffffff; border-radius: 8px 8px 0px 0px"
          >
            <span
              style="
                font-family: Inter;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: #19283d;
              "
              >Edit Contractor</span
            >
            <v-spacer></v-spacer>
            <v-icon
              tag="button"
              @click="dialog = false"
              class="text-bolder"
              color="#596A73"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <div class="px-0 px-md-8 d-flex" style="background: #f3eee8">
            <v-tabs
              mobile-breakpoint="50"
              @change="viewActiveTab"
              v-model="tab"
              background-color="#f3eee8"
              slider-size="4"
            >
              <v-tab v-for="item in VendorTabs" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>
          </div>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card style="background-color: transparent" flat>
                <div class="my-7 mx-4 mx-md-10">
                  <p class="pa-0 ma-0 text--disabled text-subtitle-2">
                    Include details about this contractor, contact person,
                    business address, default payment terms, etc
                  </p>
                </div>
                <div>
                  <v-row class="mx-4 mx-md-10">
                    <v-col cols="12" class="px-0">
                      <v-text-field
                        v-model="vendorDetails.address"
                        :rules="[rules.required]"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Company Street Address"
                      >
                      </v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mx-4 mx-md-10">
                    <v-col md="6" class="pl-0"
                      ><v-text-field
                        v-model="vendorDetails.contact.first_name"
                        :rules="[rules.required]"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="First Name"
                      ></v-text-field
                    ></v-col>
                    <v-col md="6" class="pr-0"
                      ><v-text-field
                        v-model="vendorDetails.contact.last_name"
                        :rules="[rules.required]"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Last Name"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mx-4 mx-md-10">
                    <v-col md="6" class="pl-0"
                      ><v-text-field
                        :rules="[rules.required, rules.email]"
                        v-model="vendorDetails.contact.email"
                        background-color="#ffffff"
                        type="email"
                        outlined
                        hide-details="auto"
                        placeholder="Email"
                      ></v-text-field
                    ></v-col>
                    <v-col md="6" class="pr-0"
                      ><v-text-field
                        v-model="vendorDetails.contact.phone"
                        :rules="[rules.required]"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Phone"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <!-- <v-row class="mx-4 mx-md-10">
                  <v-col md="6" class="pl-0"
                    ><v-select
                      :rules="[(v) => !!v || 'Item is required']"
                      v-model="vendorDetails.contact.role"
                      :items="role"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Roles"
                    ></v-select
                  ></v-col>
                </v-row> -->
                  <div class="d-flex justify-end py-8 mb-6 px-4">
                    <v-btn
                      class="mx-0 mr-md-5"
                      @click="switchTabs('next')"
                      dark
                      width="121"
                      height="45"
                      style="
                        background: var(--v-primary-base);
                        box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24);
                        border-radius: 4px;
                      "
                    >
                      <simple-line-icons
                        icon="arrow-right"
                        color="#FFFFFF"
                        style="
                          font-family: simple-line-icons;
                          font-style: normal;
                          font-weight: normal;
                          font-size: 12px;
                          line-height: 16px;
                        "
                        no-svg
                      />
                      <span
                        class="text-capitalize pl-3"
                        style="
                          font-family: Inter;
                          font-style: normal;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 17px;
                          text-align: center;
                          letter-spacing: 0.636364px;
                          color: #ffffff;
                        "
                        >next</span
                      >
                    </v-btn>
                  </div>
                </div>
              </v-card>
            </v-tab-item>

            <!-- BUSINESS TAB -->
            <v-tab-item>
              <v-card style="background-color: transparent" flat>
                <div class="mt-12">
                  <v-row class="mx-4 mx-md-10">
                    <v-col class="px-0"
                      ><v-text-field
                        v-model="vendorDetails.vendorname"
                        :rules="[rules.required]"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Company Name"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mx-4 mx-md-10">
                    <v-col md="6" class="pl-0"
                      ><v-select
                        :rules="[(v) => !!v || 'Item is required']"
                        v-model="vendorDetails.terms"
                        :items="paymentTerms"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Payment Terms"
                      ></v-select
                    ></v-col>
                    <v-col md="6" class="pr-0"
                      ><v-text-field
                        :rules="[rules.required, rules.digit]"
                        v-model.number="vendorDetails.zip"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Post Code"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mx-4 mx-md-10">
                    <v-col md="6" class="pl-0"
                      ><v-text-field
                        v-model="vendorDetails.city"
                        :rules="[rules.required]"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="City"
                      ></v-text-field
                    ></v-col>
                    <!-- temporary adjustments starts here -->

                    <v-col md="6" class="pr-0"
                      ><v-text-field
                        v-model="vendorDetails.state"
                        :rules="[rules.required]"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="State"
                      ></v-text-field
                    ></v-col>
                  </v-row>

                  <div class="d-flex justify-end py-6 mb-6 px-4">
                    <v-btn
                      @click="switchTabs('next')"
                      dark
                      width="121"
                      height="45"
                      class="mx-0 mr-md-5"
                      style="
                        background: var(--v-primary-base);
                        box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24);
                        border-radius: 4px;
                      "
                    >
                      <simple-line-icons
                        icon="arrow-right"
                        color="#FFFFFF"
                        style="
                          font-family: simple-line-icons;
                          font-style: normal;
                          font-weight: normal;
                          font-size: 12px;
                          line-height: 16px;
                        "
                        no-svg
                      />
                      <span
                        class="text-capitalize pl-3"
                        style="
                          font-family: Inter;
                          font-style: normal;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 17px;
                          text-align: center;
                          letter-spacing: 0.636364px;
                          color: #ffffff;
                        "
                        >next</span
                      >
                    </v-btn>
                  </div>
                </div>
              </v-card>
            </v-tab-item>

            <!-- CATEGORY TAB -->
            <v-tab-item>
              <v-card style="background-color: transparent" flat>
                <div class="mt-14">
                  <v-row class="mx-4 mx-md-10">
                    <v-col md="6" class="pl-0"
                      ><v-select
                        :rules="[(v) => !!v || 'Item is required']"
                        v-model="vendorDetails.WHT.vendor_type"
                        :items="businessCategory"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Business Category"
                      ></v-select
                    ></v-col>
                    <v-col md="6" class="pr-0"
                      ><v-select
                        :rules="[(v) => !!v || 'Item is required']"
                        v-model="vendorDetails.WHT.category"
                        :items="taxCategory"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Tax Category"
                      ></v-select
                    ></v-col>
                  </v-row>
                  <v-row class="mx-4 mx-md-10">
                    <v-col md="6" class="pl-0">
                      <v-select
                        class="mt-0"
                        :rules="[(v) => !!v || 'Item is required']"
                        v-model="vendorDetails.currency"
                        :items="currency"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Currency"
                      ></v-select>
                    </v-col>
                    <v-col md="6" class="pr-0">
                      <v-select
                        :rules="[(v) => !!v || 'Item is required']"
                        v-model="vendorDetails.business_category"
                        :items="industry"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Industry Type"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mx-4 mx-md-10">
                    <v-col class="px-0">
                      <TagsInputComponent
                        :tagsArray="TagsArray"
                        @addTag="addTag"
                        @removeLastTag="removeLastTag"
                      />
                    </v-col>
                  </v-row>
                  <div class="mx-4 mx-md-10 pt-3">
                    <v-switch
                      class="mt-0"
                      v-model="vendorDetails.apply_tax"
                      color="primary"
                      :label="`Apply tax: ${vendorDetails.apply_tax.toString()}`"
                    ></v-switch>
                  </div>
                  <div class="d-flex justify-end py-6 mb-6 px-4">
                    <v-btn
                      @click="switchTabs('next')"
                      dark
                      width="121"
                      height="45"
                      class="mx-0 mr-md-5"
                      style="
                        background: var(--v-primary-base);
                        box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24);
                        border-radius: 4px;
                      "
                    >
                      <simple-line-icons
                        icon="arrow-right"
                        color="#FFFFFF"
                        style="
                          font-family: simple-line-icons;
                          font-style: normal;
                          font-weight: normal;
                          font-size: 12px;
                          line-height: 16px;
                        "
                        no-svg
                      />
                      <span
                        class="text-capitalize pl-3"
                        style="
                          font-family: Inter;
                          font-style: normal;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 17px;
                          text-align: center;
                          letter-spacing: 0.636364px;
                          color: #ffffff;
                        "
                        >next</span
                      >
                    </v-btn>
                  </div>
                </div>
              </v-card>
            </v-tab-item>

            <!-- BANK TAB -->
            <v-tab-item>
              <v-card style="background-color: transparent" flat>
                <div class="">
                  <div class="mx-4 mx-md-10">
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        v-bind="size"
                        outlined
                        color="#525e6e"
                        class="mt-4 mb-3 text-capitalize hover-btn"
                        :style="{
                          'background-color': hover ? '#2bd5ae' : '',
                          border: hover ? 'none' : '',
                        }"
                      >
                        <v-icon color="#525E6E" v-bind="iconSize"
                          >mdi-chevron-right</v-icon
                        >
                        <span>Invite Contractor</span>
                      </v-btn>
                    </v-hover>

                    <p class="text--secondary d-block text-caption py-1">
                      Click the button above to invite this contractor to enter
                      and confirm banking information. Alternativly, you can
                      fill the banking informationn below
                    </p>
                  </div>
                  <v-row class="mx-4 mx-md-10">
                    <v-col class="pl-0"
                      ><v-select
                        :rules="[(v) => !!v || 'Item is required']"
                        v-model="vendorDetails.bankname"
                        :items="banks"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Bank Name"
                      ></v-select
                    ></v-col>
                  </v-row>
                  <v-row class="mx-4 mx-md-10">
                    <v-col class="pl-0"
                      ><v-text-field
                        v-model="vendorDetails.bankaccount"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="Bank Acccount Number"
                      ></v-text-field
                    ></v-col>
                    <v-col class=""
                      ><v-text-field
                        v-model.number="vendorDetails.taxid"
                        :rules="[rules.required]"
                        background-color="#ffffff"
                        outlined
                        hide-details="auto"
                        placeholder="TIN"
                      ></v-text-field
                    ></v-col>
                  </v-row>

                  <div class="mx-4 mx-md-10 pt-3">
                    <v-switch
                      class="mt-0"
                      color="primary"
                      v-model="vendorDetails.approved"
                    >
                    </v-switch>
                    <p class="text-subtitle-2 mb-0">
                      This vendor is approved to do business with your
                      organization
                    </p>
                  </div>
                  <div class="d-flex justify-end py-6 mb-6 px-4">
                    <v-btn
                      @click="saveAction"
                      dark
                      width="121"
                      height="45"
                      class="mx-0 mr-md-6"
                      style="
                        background: var(--v-primary-base);
                        box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24);
                        border-radius: 4px;
                      "
                    >
                      <simple-line-icons
                        icon="arrow-right"
                        color="#FFFFFF"
                        style="
                          font-family: simple-line-icons;
                          font-style: normal;
                          font-weight: normal;
                          font-size: 12px;
                          line-height: 16px;
                        "
                        no-svg
                      />
                      <span
                        class="text-capitalize pl-3"
                        style="
                          font-family: Inter;
                          font-style: normal;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 17px;
                          text-align: center;
                          letter-spacing: 0.636364px;
                          color: #ffffff;
                        "
                        >Save</span
                      >
                    </v-btn>
                  </div>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TagsInputComponent from "@/components/TagsInputComponent.vue";
import contactDetails from "@/mixins/contactDetails.js";

export default {
  data() {
    return {
      dialog: false,
      vendorDetails: {
        address: "",
        contact: {
          first_name: "",
          last_name: "",
          phone: "",
          email: "",
        },
        vendorname: "",
        //accounting_code: "",
        terms: "",
        zip: "",
        city: "",
        state: "",
        WHT: {
          vendor_type: null,
          category: null,
        },
        apply_tax: true,
        bankname: "",
        bankaccount: "",
        taxid: "",
        currency: "",
        approved: null,
        business_category: null,
      },

      tab: null,
      VendorTabs: ["Contact", "Business", "Category", "Bank"],

      rules: {
        required: (v) => !!v || "This field is required",
        digit: (v) =>
          Number.isInteger(Number(v)) || "The value must be an integer number",
        email: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      },
    };
  },
  components: { TagsInputComponent },
  mixins: [contactDetails],
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),
    viewActiveTab(e) {
      console.log({ e });
    },
    switchTabs(direction) {
      // console.log(direction);
      switch (direction) {
        case "next":
          if (this.tab <= this.VendorTabs.length - 2) {
            this.tab++;
            return;
          }
          if (this.tab == this.VendorTabs.length - 1) {
            this.saveAction();
          }
          break;
        default:
          break;
      }
    },

    //Add new Tags to the TagsArray
    addTag(value) {
      let val = value.trim();
      this.TagsArray.push(val);
    },

    //Remove Tags from the TagsArray
    removeLastTag() {
      this.TagsArray.splice(this.TagsArray.length - 1, 1);
    },
    async saveAction() {
      const newTagArray = this.TagsArray.reduce((acc, cur) => {
        acc.push({ label: cur, tag_name: cur });
        return acc;
      }, []);
      console.log(JSON.stringify(newTagArray, null, 2));
      let data = {
        ...this.vendorDetails,
        tag: newTagArray,
      };
      const id = this.$route.params.id;
      if (this.$refs.editVendorForm.validate()) {
        try {
          await this.$store.dispatch("contacts/updateVendorDetails", {
            id,
            payload: data,
          });
          this.showToast({
            sclass: "success",
            show: true,
            message: "Vendor details Updated successfully..",
            timeout: 3000,
          });
          await this.fetchVendorDetails();
          this.dialog = false;
        } catch (error) {
          console.log(error);
          this.showToast({
            sclass: "error",
            show: true,
            message: "failed to Update",
            timeout: 3000,
          });
        }
      }
    },
    show(value) {
      this.dialog = value;
    },

    async fetchVendorDetails() {
      const id = this.$route.params.id;
      await this.$store
        .dispatch("contacts/getSingleVendor", id)
        .then((response) => {
          if (response.status == 200) {
            this.vendorDetails.vendorname = response.data.vendorname || "";
            this.vendorDetails.contact.first_name =
              response.data.contact?.first_name || "";
            this.vendorDetails.contact.last_name =
              response.data.contact?.last_name || "";
            this.vendorDetails.contact.email = response.data.email || "";
            this.vendorDetails.address = response.data.address || "";
            this.vendorDetails.contact.phone =
              response.data.contact?.phone || "";
            // this.vendorDetails.contact.role = response.data.contact.role;
            this.vendorDetails.terms = response.data.terms;
            this.vendorDetails.zip = response.data.zip || "";
            this.vendorDetails.city = response.data.city || "";
            this.vendorDetails.state = response.data.state || "";
            this.vendorDetails.WHT.vendor_type =
              response.data.WHT.vendor_type || "";
            this.vendorDetails.WHT.category = response.data.WHT?.category || "";
            this.vendorDetails.apply_tax = response.data.apply_tax || false;
            this.vendorDetails.bankname = response.data.bankname || "";
            this.vendorDetails.taxid = response.data.taxid || "";
            this.vendorDetails.business_category =
              response.data.business_category || "";
            this.vendorDetails.bankaccount = response.data.bankaccount || "";
            this.vendorDetails.currency = response.data.currency || "";
            this.vendorDetails.approved = response.data.approved || "";
            this.TagsArray = response.data.tag.map((str) => str.label);
          }
        });
    },
  },
  computed: {
    ...mapGetters({
      singleVendor: "contacts/getVendor",
    }),

    //dynamic button size and icon
    size() {
      const size = { xs: "x-small", sm: "small", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    iconSize() {
      const size = { xs: "x-small", sm: "small" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
  },
  async mounted() {
    await this.fetchVendorDetails();
  },
};
</script>

<style scoped>
.chipText {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #7f919b;
}

.headerText {
  margin-left: 52px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.45px;
  color: #7f919b;
}

i.sli-font {
  font-size: 12px;
  display: inline-block;
}

.select {
  margin-right: 260px;
  margin-left: 52px;
}

.theme--light.v-tabs-items {
  background-color: transparent !important;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 0;
}
</style>

<template>
  <div>
    <ButtonActionContainer :currency="$appSettings.currency" v-if="$appSettings && $appSettings.currency" />
    <v-layout row class="align-center pb-4 pt-6 pl-6 pr-14 text-body-2" v-if="$vuetify.breakpoint.mdAndUp">
      <v-flex md1 class="d-flex flex-row">
        <div class="d-flex align-center">
          <p class="mb-0 primary--text font-weight-bold">#</p>
        </div>
      </v-flex>
      <v-flex md3 class="d-flex flex-row">
        <div class="d-flex align-center">
          <p class="mb-0 primary--text font-weight-bold">Ref. No.</p>
        </div>
      </v-flex>
      <v-flex md2>
        <div class="d-flex align-center">
          <p class="mb-0 primary--text font-weight-bold">Status</p>
        </div>
      </v-flex>
      <v-flex md2>
        <div class="d-flex align-center">
          <p class="mb-0 primary--text font-weight-bold">Created</p>
        </div>
      </v-flex>
      <v-flex md2>
        <div class="d-flex align-center">
          <p class="mb-0 primary--text font-weight-bold">Amount</p>
        </div>
      </v-flex>
      <!-- <v-flex md3>
        <div class="d-flex ms-6">
          <p class="mb-0 primary--text font-weight-bold"></p>
        </div>
      </v-flex> -->
      <v-flex md2>
        <div class="d-flex justify-center align-center">
          <p class="mb-0 primary--text font-weight-bold"></p>
        </div>
      </v-flex>
    </v-layout>
    <v-row v-if="customerInvoices && customerInvoices.length > 0">
      <v-col cols="12" v-for="(invoice, i) in displayedItems" :key="i" class="py-0 ma-0">
        <v-skeleton-loader class="mt-4 mt-md-0 px-6 px-md-0" :type="$vuetify.breakpoint.mdAndUp ? 'text' : 'card'"
          :loading="isloading">
          <customerInvoiceCard v-if="$vuetify.breakpoint.mdAndUp" :index="i + 1" :id="invoice.id"
            :RefNumber="invoice.invoicenumber || 'N/A'" :status="invoice.status || 'N/A'" :total="invoice.total"
            :created="invoice.created_at | moment('DD-MM-YYYY')" :hypn_id="invoice.hypn_id" />
          <MobileTableCard v-if="$vuetify.breakpoint.smAndDown" :index="i + 1" :id="invoice.id" :hypn_id="invoice.hypn_id"
            :refNumber="invoice.invoicenumber || 'N/A'" :cardTitle="cardTitle" :status="invoice.status || 'N/A'"
            :total="invoice.total" :created="invoice.created_at | moment('DD-MM-YYYY')" />
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <div class="d-flex justify-center align-center pt-6" v-else>
      <span class="text--primary text-h5 font-monospace">No Data available</span>
    </div>

    <div class="pagination__container px-4" v-if="customerInvoices && customerInvoices.length > 0">
      <BasePagination :current-page="currentPage" :visible-pages="visiblePageValue" :total-pages="totalPages"
        @page-changed="handlePageChange" />
    </div>
  </div>
</template>

<script>
import CustomerInvoiceCard from "./CustomerInvoiceCard.vue";
import MobileTableCard from "./MobileTableCard.vue";
import ButtonActionContainer from "../buttonActionContainer.vue";
import { mapActions, mapGetters } from "vuex";
import currencyFilter from "@/mixins/currencyFilter";
import pagination from "@/mixins/pagination";
import BasePagination from "@/components/BasePagination.vue";

export default {
  name: "Customer-Invoice",
  props: {
    searchQuery: String,
  },
  components: {
    CustomerInvoiceCard,
    MobileTableCard,
    ButtonActionContainer,
    BasePagination,
  },

  data() {
    return {
      dialog: false,
      cardTitle: "Invoices",
      isloading: false,
      itemsPerPage: 5,
    };
  },
  mixins: [pagination, currencyFilter],
  computed: {
    ...mapGetters({
      customerInvoices: "contacts/getCustomerInvoicesById",
      currency: "contacts/getCustomerCurrency",
      organizationToken: "organizations/OrganToken",
      organID: "organizations/getOrganizationID",
    }),

    // filter the customerInvoices array with the search variable
    filteredInvoices() {
      if (this.searchQuery) {
        const query = String(this.searchQuery).toLowerCase().split(" ");
        return this.customerInvoices?.filter((item) => {
          return query.every((term) =>
            item.invoicenumber?.toLowerCase().includes(term)
          );
        });

        // return this.$moment(item.created_at).format('DD-MM-YYYY');
      } else {
        return this.customerInvoices;
      }
    },

    // get the length of the filtered form array to use it as the value of the visible page
    visiblePageValue() {
      const pageNumber = Math.floor(
        this.filteredInvoices.length / this.itemsPerPage
      );
      return pageNumber > 5 ? 5 : pageNumber < 1 ? 1 : pageNumber;
    },

    // get array per page
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      if (
        this.filteredInvoices &&
        this.filteredInvoices.length > this.itemsPerPage
      ) {
        return this.filteredInvoices.slice(startIndex, endIndex);
      } else {
        return this.filteredInvoices.slice();
      }
    },
    totalPages() {
      return Math.ceil(this.filteredInvoices.length / this.itemsPerPage);
    },
  },

  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    //fetch all the invoices belonging to a customer using the organization id and customer id
    async getCustomerInvoices() {
      try {
        this.isloading = true;
        const customerId = this.$route.params.id;

        // dispatch the action to fetch the customer invoices only when the organization id is not null or undefined
        if (this.organID) {
          await this.$store.dispatch(
            "contacts/getCustomerInvoices",
            customerId
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isloading = false;
      }
    },
  },

  // mounted() {
  //   setTimeout(() => {
  //     this.isloading = false;
  //   }, 3000);
  // },

  watch: {
    // organizationToken: {
    //   handler() {
    //     console.log("organizationID", this.organID);
    //     this.getCustomerInvoices();
    //   },
    //   immediate: true,
    //   deep: true,
    // },

    // watch the organId if its not null or undefined, then fetch the customer invoices
    organID: {
      handler() {
        if (this.organID) {
          this.getCustomerInvoices();
        }
      },
      immediate: true,
      deep: true,
    },

    // watch the route params if the id changes, then fetch the customer invoices
    "$route.params.id": {
      handler() {
        this.getCustomerInvoices();
      },

      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.switch-card {
  height: 53px;
  background: rgba(127, 145, 155, 0.052607);
}

.switch {
  width: 15.28px;
  height: 15.28px;
}

.export-btn {
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.636364px;

  color: #19283d;
}

i.sli-font {
  font-size: 12px !important;
  display: inline-block !important;
}

.material-icons {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #19283d;
}

.pagination__container {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-top: 6px;
}
</style>
